import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Note from '../../components/note';
import BabelConfig from './babel-config.md';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    <h1 {...{
      "id": "webpack"
    }}>{`Webpack`}</h1>
    <Note>
  TODO: Add plugin documentation
    </Note>
    <p>{`MDX provides a built in webpack loader you need to install and configure
for webpack projects.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install --save-dev @mdx-js/loader@next
`}</code></pre>
    <h2 {...{
      "id": "configuration"
    }}>{`Configuration`}</h2>
    <p>{`The loader needs to be used in tandem with the `}<a parentName="p" {...{
        "href": "https://webpack.js.org/loaders/babel-loader/"
      }}>{`babel-loader`}</a>{`.  Most projects will typically
already include this if you are using JSX syntax.`}</p>
    <p>{`For webpack projects you can define the following `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{` extension
handler for `}<inlineCode parentName="p">{`.md`}</inlineCode>{` and `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  module: {
    // ...

    rules: [
      // ...

      {
        test: /\\.mdx?$/,
        use: [
          'babel-loader',
          '@mdx-js/loader'
        ]
      }
    ]
  }
}
`}</code></pre>
    <p>{`If you only want the loader for `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` files you can change the regex to `}<inlineCode parentName="p">{`/\\.mdx$/`}</inlineCode>{`.`}</p>
    <p>{`The transpiled output for MDX requires `}<a parentName="p" {...{
        "href": "https://babeljs.io"
      }}>{`babel`}</a>{` to be run.  This is typically
by adding in the babel-loader to run `}<em parentName="p">{`after`}</em>{` the MDX loader.  Webpack starts
from the end of the loaders array and works backward, so it is important to
follow the ordering above.`}</p>
    <BabelConfig />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      